<script lang='ts'>
import { createEventDispatcher, onMount } from "svelte";
import { fade } from "svelte/transition";
import Center from "./Center.svelte";

export let html = "[html]";
export let duration = 1300;

let visible = true

const emit = createEventDispatcher()
const FADE_DURATION = 400

onMount(() => {
  setTimeout(() => {
    visible = false
  }, FADE_DURATION+duration)
})
</script>

<Center>
  {#if visible}
    <div transition:fade={{duration: FADE_DURATION+duration}} on:outroend={() => emit('complete')}>
      {@html html}
    </div>
  {/if}
</Center>