<script lang='ts'>
  import type { Writable } from "svelte/store";
import { createEventDispatcher } from "svelte";
import { fade } from "svelte/transition";
import { writable } from "svelte/store";
import Center from "./Center.svelte";
export let html: Writable<string> = writable("require writable as props")
export let completed: Writable<boolean> = writable(false)
const emit = createEventDispatcher()

const DURATION = 400
$: if ($completed) {
  setTimeout(() => {
    emit('complete')
  }, DURATION)
}
</script>

<Center>
  {#if !$completed}
    <div out:fade={{ duration: DURATION }}>
      {@html $html}
    </div>
  {/if}
</Center>