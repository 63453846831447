<script lang="ts">
import { Howl } from 'howler';
import { ID_INTRO, ID_LOVE, ID_SOFT, ID_COOL, ID_STRONG, ID_ENCOURAGE } from '../lib/symbol';

function create_howl(url: string) {
	return new Howl({
		src: [url],
		autoplay: false,
		loop: true,
		volume: 0.5
	});
}

// prettier-ignore
const songs: Record<symbol, Howl> = <const>{
  [ID_INTRO]:  create_howl('/songs/intro.mp3'),
  [ID_SOFT]:   create_howl('/songs/soft.mp3'),
  [ID_LOVE]:   create_howl('/songs/love.mp3'),
  [ID_STRONG]: create_howl('/songs/strong.mp3'),
  [ID_COOL]:   create_howl('/songs/cool.mp3'),
}

export function play(key: keyof typeof songs, { duration = 1000, delay = 0 } = {}) {
  console.log('play')
	for (const song of Reflect.ownKeys(songs)) {
    console.log([song, key])
		const howl: Howl = songs[song];
		if (song == key) {
      console.log('equal')
      setTimeout(() => {
        howl.play()
        howl.fade(howl.volume(), 1, duration);
      }, delay);
		} else {
			howl.fade(howl.volume(), 0, 1000);
		}
	}
}

export function pause_all() {
	for (const song of Reflect.ownKeys(songs)) {
		const howl: Howl = songs[song];
		howl.fade(howl.volume(), 0, 500);
	}
}
</script>
