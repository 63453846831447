<Center>
	{#if visible}
		<p transition:fade={FADE_CONFIG} on:outroend={() => emit('complete')} class='text-center px-6'>
			{@html label}
		</p>
	{/if}
</Center>

<svelte:window on:click|once={() => (visible = false)} />

<script lang="ts">
import { FADE_CONFIG } from '../lib/config';

import { createEventDispatcher, onDestroy, onMount } from 'svelte';
import { fade } from 'svelte/transition';

import Center from './Center.svelte';

let emit = createEventDispatcher();
let visible = true;

export let label: string = 'NO LABEL';

onMount(() => {
	document.body.classList.add('cursor-pointer');
});
onDestroy(() => {
	document.body.classList.remove('cursor-pointer');
});
</script>
