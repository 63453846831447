<script lang='ts'>
import { createEventDispatcher, onMount, tick } from "svelte";
const emit = createEventDispatcher()
onMount(async () => {
  await tick()
  await tick()
  emit('complete')
})
</script>
<div class='hidden'>
  HIDDEN CONTENT
</div>