<svelte:options immutable={true} />

<div
	bind:clientHeight={wrapper_height}
	class="flex justify-center h-full"
	class:items-center={content_not_overflow}>
	{#if visible}
		<form
			class="px-2 py-12"
			style="max-width: 400px; height: min-content"
			bind:clientHeight={form_height}
			transition:fade={FADE_CONFIG}
			on:outroend={() => emit('complete')}
			on:submit|preventDefault={onSubmit}>
			<label class="mb-4 flex justify-center text-center" for={label}>
				<div>{@html label}</div>
			</label>

			<div class="grid grid-cols-2 md:grid-cols-2 gap-2">
				{#each value as { label, value: checked }}
					<label
						class="checkbox p-2 flex justify-left items-center space-x-2 button is-primary cursor-pointer">
						<input type="checkbox" checked={get(checked)} on:change={e => checked.set(e.target['checked'])} />
						<div>{@html label}</div>
					</label>
				{/each}
			</div>

			<div class="text-sm text-red-600 mt-4">{error_message}</div>
			<button type="submit" class="button is-primary mt-4 w-full">ไปต่อ</button>
		</form>
	{/if}
</div>

<script lang="ts">
import { createEventDispatcher } from 'svelte';
import { writable, get } from 'svelte/store';
import type { Writable } from 'svelte/store';
import { fade } from 'svelte/transition';
import { FADE_CONFIG } from '../lib/config';
import every from 'lodash/every';
import { gsap } from 'gsap';

let emit = createEventDispatcher();
let visible = true;

let wrapper_height: number;
let form_height: number;
let error_message = ''

$: content_not_overflow = form_height < wrapper_height;

export let label: string = 'NO LABEL';
export let value: { label: string; value: Writable<boolean>; meta: any }[] = [
	{ label: 'CHOICE 1', value: writable(false), meta: {} },
	{ label: 'CHOICE 2', value: writable(false), meta: {} },
	{ label: 'CHOICE 3', value: writable(false), meta: {} },
	{ label: 'CHOICE 4', value: writable(false), meta: {} }
];

function onSubmit() {
	console.log(value)
	console.log('onsubmit', every(value, v => get(v.value) == false))
	if (every(value, v => get(v.value) == false)) {
		error_message = 'ต้องเลือกอย่างน้อยหนึ่งคำตอบ'
		return;
	} else {
		gsap.to('.checkbox', { scale: 0.8, alpha: 0, stagger: .05, onComplete: () => { visible = false }})
	}
}
</script>
