<HowlerComponent bind:this={howler_instance} />

<!-- <canvas bind:this={canvas} class='z-10 inset-0 w-screen h-screen'></canvas> -->
<!-- <Turbulence /> -->
<!-- <Spore /> -->

<div class="relative z-0 h-full w-full">
	{#key curr_block_static.key}
		<svelte:component
			this={curr_block_static.component}
			{...curr_block_static.props}
			bind:this={curr_block_static_instance} />
	{/key}
</div>

<script context="module" lang="ts">
export const prerender = false;
export const ssr = false;
</script>

<script lang="ts">
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import maxBy from 'lodash/maxBy';
import shuffle from 'lodash/shuffle';
import sumBy from 'lodash/sumBy';
import uniqueId from 'lodash/uniqueId';
import { gsap } from 'gsap'

import { onMount } from 'svelte/internal';
import type { SvelteComponentTyped } from 'svelte';
import type { Writable } from 'svelte/store';
import { get, writable } from 'svelte/store';

import InputComponent from '../component/Input.svelte';
import MessageComponent from '../component/Message.svelte';
import SelectComponent from '../component/Select.svelte';
import MultipleCheckboxComponent from '../component/MultipleCheckbox.svelte';
import NoopComponent from '../component/Noop.svelte';
import BlankComponent from '../component/Blank.svelte';
import ResultCard from '../component/ResultCard.svelte';
import HowlerComponent from '../component/Howler.svelte';
import DisplayComponent from '../component/Display.svelte';
import FadeComponent from '../component/Fade.svelte';
import Turbulence from '../component/Turbulence.svelte';
import Spore from '../component/Spore.svelte'

import mapValues from 'lodash/mapValues.js';
import { ID_INTRO, ID_LOVE, ID_SOFT, ID_COOL, ID_STRONG, ID_ENCOURAGE, ID_RESULTCARD } from '../lib/symbol';
import range from 'lodash/range';
import sample from 'lodash/sample';
import sampleSize from 'lodash/sampleSize';
import { every, identity, isString, random, values } from 'lodash';
import { dev } from '../lib/env';

import * as PIXI from 'pixi.js'
import PixiPlugin from 'gsap/PixiPlugin'
import Display from '../component/Display.svelte';

// import * as PIXI from '../../web_modules/pixijs.js'
// import type { Loader } from '@pixi/loaders'
// import { Sprite } from '@pixi/sprite'
// import { Sprite } from '@pixi/constants'
// let PIXI: {
// 	Application: Application,
// 	Loader: Loader,
// 	Sprite: Sprite
// }
// let PIXI: any
let canvas: HTMLCanvasElement
let app: PIXI.Application
let loader: PIXI.Loader

const CSS_PROPERTY_COLOR_FONT = '--color-font'
const CSS_PROPERTY_COLOR_PRIMARY = '--color-primary'
const CSS_PROPERTY_COLOR_PRIMARY_INVERSE = '--color-primary-inverse'
const CSS_PROPERTY_COLOR_BACKGROUND = '--color-background'
const CSS_PROPERTY_TRANSITION_DURATION = '--transition-duration'
const colors = <const>{
	black: '#202020',
	white: '#ffffff',
	pink: '#fbd5f7',
}
let root: typeof document.documentElement.style

class AnimationStore {
	timelines: (gsap.core.Timeline | gsap.core.Tween)[] = []
	add_timeline(tl: gsap.core.Timeline | gsap.core.Tween) {
		this.timelines.push(tl)
		return tl;
	}
	async fadeout_all(opt: gsap.TweenVars = {}) { // also remove all child from app.stage
		await gsap.to(app.stage, { ...opt, alpha: 0 }).then()
		this.timelines.forEach(tl => tl.kill())
		this.timelines = []
		app.stage.removeChildren()
		app.stage.alpha = 1
	}
	static load_sprite(url: string | PIXI.Texture, opt: gsap.TweenVars["pixi"] = {}) {
		let sp = new PIXI.Sprite(isString(url) ? loader.resources[url].texture : url)
		gsap.set(sp, { pixi: { anchor: 0.5, ...opt } })
		return sp
	}
}
const animation_store = new AnimationStore()

type Class<T> = { new (...args: any): T };
type Props<T> = T extends SvelteComponentTyped<infer P> ? P : never;
type Id = Symbol;

type BlockFunctionBase = { function: (data: PlainStore) => any | Promise<any>; command: 'run' };
type BlockFunctionRecursive = {
	function: (data: PlainStore) => Id | Promise<Id>;
	command: 'recursive';
};
type BlockFunction = BlockFunctionBase | BlockFunctionRecursive;
type BlockStatic<T> = { component: Class<T>; props?: Props<T> };
type BlockContainer = { id: Id; blocks: Blocks };

type Blocks =
	| BlockFunction
	| BlockStatic<any>
	| BlockContainer
	| Blocks[]
	| ((data: PlainStore) => Blocks | Promise<Blocks>);

/* --------------------------- COMPONENT BBUILDER --------------------------- */

function choice(o: {
	label: string;
	select: string[];
	value?: Writable<string>;
}): BlockStatic<SelectComponent> {
	return {
		component: SelectComponent,
		props: {
			label: o.label,
			options: o.select,
			value: o.value ?? undefined
		}
	};
}
function choice3(label: string, value?: Writable<string>) {
	return choice({
		label,
		select: ['เคยสิ', 'ก็มีบ้าง', 'ไม่เคยเลย'],
		value
	});
}
function choice2(label: string, o: { ก็มีนะ: Blocks; ไม่มีเลย: Blocks }): Blocks {
	const value = writable('' as 'ก็มีนะ' | 'ไม่มีเลย');
	return [
		choice({
			label,
			select: ['ก็มีนะ', 'ไม่มีเลย'] as (keyof typeof o)[],
			value
		}),
		function branch() {
			return o[get(value)];
		}
	];
}
function message(label: string): BlockStatic<MessageComponent> {
	return { component: MessageComponent, props: { label } };
}
function input(label: string, value?: Writable<string>): BlockStatic<InputComponent> {
	return {
		component: InputComponent,
		props: {
			label,
			value: value || undefined
		}
	};
}
function component<T extends SvelteComponentTyped>(
	comp: Class<T>,
	props?: Props<T>
): BlockStatic<any> {
	return {
		component: comp,
		props: props || {}
	};
}
function multiplecheckbox(o: {
	label: string;
	checkboxs: { category: Id; label: string }[];
	value: Writable<Id>;
	onSelected?: (categories: Symbol[]) => void
}): Blocks {
	const checkboxs = o.checkboxs.map((o) => ({
		label: o.label,
		value: writable(false),
		meta: { category: o.category }
	}));
	const component: BlockStatic<MultipleCheckboxComponent> = {
		component: MultipleCheckboxComponent,
		props: {
			label: o.label,
			value: shuffle(checkboxs)
		}
	};
	return [
		component,
		fun(() => {
			const categories = checkboxs.filter((v) => get(v.value) == true).map((v) => v.meta.category);
			if (isFunction(o.onSelected)) {
				o.onSelected(categories)
			}
			const best_category = maxBy(categories, (cat) =>
				sumBy(categories, (c) => (c == cat ? 1 : 0))
			);
			console.log({
				categories,
				checkboxs: checkboxs.map((v) => get(v.value))
			});
			if (best_category) {
				return o.value.set(best_category);
			} else {
				if (dev) {
					return o.value.set(ID_LOVE);
				}
				throw new Error('catarory can not be found');
			}
		})
	];
}

function block(id: Id, blocks: Blocks[]): BlockContainer {
	return {
		id: id,
		blocks: blocks
	};
}
function recursive(fn: (data: PlainStore) => Id | Promise<Id>): BlockFunctionRecursive {
	return {
		function: fn,
		command: 'recursive'
	};
}
function fun(fn: (data: PlainStore) => any | Promise<any>): BlockFunctionBase {
	return {
		function: fn,
		command: 'run'
	};
}
function parallel(fn: (data: PlainStore) => any | Promise<any>): BlockFunctionBase {
	return {
		function: (x) => { fn(x) },
		command: 'run'
	};
}
function sleep(second: number) {
	return fun(() => new Promise(resolve => {
		setTimeout(() => { 
			resolve(null)
		}, second * 1000)
	}))
} 

/* ---------------------------------- STORY --------------------------------- */

const ID_MAIN = ID_INTRO;

let howler_instance: HowlerComponent;

// prettier-ignore
const store = {
	youare_adj:  writable((dev ? "[youare_adj]"  : null) as unknown as 'เปี่ยมไปด้วยความรัก' | 'อ่อนโยน' | 'เข้มแข็ง' | 'เจ๋ง'),
	youare_noun: writable((dev ? "[youare_noun]" : null) as unknown as 'การเปี่ยมไปด้วยความรัก' | 'ความอ่อนโยน' | 'ความเข้มแข็ง' | 'ความเจ๋ง'),
	youare_role: writable((dev ? "[youare_role]" : null) as unknown as 'นักเรียนม.ปลาย' | 'นักกีฬาวิ่ง' | 'นักท่องเที่ยว' | 'คนกำลังจะย้ายบ้าน'),
	have_story:  writable((dev ? "[have_story]"  : null) as unknown as true | false),
	main_branch: writable((dev ? "[main_branch]" : null) as unknown as symbol),
	name:        writable((dev ? "[name]" : '') as unknown as string),
	ever_tell_yourself: writable((dev ? "[ever_tell_yourself]" : null) as unknown as 'เคยสิ' | 'ก็มีบ้าง' | 'ไม่เคยเลย'),
};
type OriginalStore = typeof store;
type PlainStore = {
	[key in keyof OriginalStore]: OriginalStore[key] extends Writable<infer P> ? P : never;
};
function get_plain_store(): PlainStore {
	return mapValues(store, (v: Writable<any>) => get(v));
}

let unsafe_global_timeline: gsap.core.Timeline = gsap.timeline()
const unsafe = {
	gsap_timeline_remove_grid_heart_element: (remain: number) => gsap.timeline()

}

// prettier-ignore
const pages: Blocks[] = [
	block(ID_INTRO, [
		block(Symbol("ID_LOADING"), [
			() => {
				const progress = writable('0%')
				const completed = writable(false)
				loader.onProgress.add(() => {
					progress.set(`${Math.round(loader.progress)}%`)
				})
				loader.onComplete.add(() => {
					completed.set(true)
				})
				loader.add([
					'/actionplay-logo.png',
					"/bg-actionplay-02-mini.png",
					'/element/element-actionplay-01.png',
					'/element/element-actionplay-02.png',
					'/element/element-actionplay-03.png',
					'/element/element-actionplay-04.png',
					'/element/element-actionplay-05.png',
					'/element/element-actionplay-06.png',
					'/element/element-actionplay-07.png',
					'/element/element-actionplay-08.png',
					'/element/element-actionplay-09.png', // blue
					'/element/element-actionplay-10.png',
					'/element/element-actionplay-11.png',
					'/element/element-actionplay-12.png',
					'/element/element-actionplay-13.png',
					'/element/element-actionplay-14.png',
					'/element/element-actionplay-15.png',
					'/element/element-actionplay-16.png',
					'/element/element-actionplay-17.png',
					'/element/element-actionplay-18.png',
					'/element/element-actionplay-19.png',
					'/element/element-actionplay-20.png',
					'/element/element-actionplay-door.png',
				])
				loader.load()
				return component(DisplayComponent, {
					html: progress,
					completed: completed
				})
			},
		]),
		block(Symbol("ID_HACKING"), [
			() => {
				if (dev) {
					return [
						// recursive(() => ID_RESULTCARD),
						// recursive(() => ID_COOL),
						// recursive(() => ID_ENCOURAGE),
						// message('hack end'),
					]
				} else {
					return []
				}
			}
		]),
		fun(() => {
			/* --------------------------------- SPRITE --------------------------------- */

			let blurbg = AnimationStore.load_sprite("/bg-actionplay-02-mini.png", { width: app.screen.width, height: app.screen.height })

			const backhole = AnimationStore.load_sprite('/element/element-actionplay-13.png')

			const door_txture = loader.resources['/element/element-actionplay-door.png'].texture!
			const door_scale = Math.min((app.screen.height * 2) / door_txture.height, app.screen.width / door_txture.width)
			const door = AnimationStore.load_sprite(door_txture, { scale: door_scale, anchorX: 0.5, anchorY: 0 })
			door.anchor.set(0.5, 0)

			const HEART_SIZE = 100
			const heart = AnimationStore.load_sprite('/element/element-actionplay-14.png', { scale: 1 })
			const heart_container = new PIXI.Container()
			heart_container.addChild(heart)
			const heart_textures = [
				'/element/element-actionplay-14.png',
				'/element/element-actionplay-15.png',
				'/element/element-actionplay-16.png',
				'/element/element-actionplay-17.png',
				'/element/element-actionplay-18.png',
				'/element/element-actionplay-19.png',
				'/element/element-actionplay-20.png',
			]
			const xy = range(-2, 2+1).flatMap(i => range(-4, 4+1).map(j => <const>[i, j])).filter(([i, j]) => i != 0 || j != 0)
			const hearts_rest = xy.map(([i, j]) => AnimationStore.load_sprite(sample(heart_textures), 
			{ scale: 1, width: HEART_SIZE, height: HEART_SIZE, positionX: i * HEART_SIZE, positionY: j * HEART_SIZE }))
			
			unsafe.gsap_timeline_remove_grid_heart_element = (remain) => {
				console.log('call fn gsap_timeline_remove_grid_heart_element', remain)
				return gsap.timeline()
					.to(sampleSize([heart, ...hearts_rest], hearts_rest.length + 1 - remain), { alpha: 0, stagger: { amount: 0.3, from: 'random' }  })
					.play()
			}
				
			const water_drops = range(3).map(i => AnimationStore.load_sprite("/element/element-actionplay-09.png", { scale: 0.9 + 0.3 * i }))
			const water_drops_container = new PIXI.Container()
			water_drops_container.addChild(...water_drops.reverse())
				
			const { width, height } = app.screen
			const bubble_textures = [
				'/element/element-actionplay-03.png',
				'/element/element-actionplay-04.png',
				'/element/element-actionplay-05.png',
				'/element/element-actionplay-06.png',
				'/element/element-actionplay-07.png',
				'/element/element-actionplay-08.png',
				'/element/element-actionplay-09.png',
				'/element/element-actionplay-10.png',
				'/element/element-actionplay-11.png',
				'/element/element-actionplay-12.png',
				'/element/element-actionplay-13.png',
			]
			const bubbles = range(9).map(i => {
				return AnimationStore.load_sprite(sample(bubble_textures), { scale: random(0.2, 1.2), x: random(-width / 2, width / 2), alpha: 0.4 })
			})
			const bubbles_container = new PIXI.Container()
			bubbles_container.addChild(...bubbles)

			/* -------------------------------- PARALLEL -------------------------------- */

			const bounce_heart = animation_store.add_timeline(gsap.to(heart, { pixi: { scale: 1.05 }, repeat: -1, repeatDelay: 0.5, yoyo: true, ease: 'power3.out', yoyoEase: 'power3.in', duration: 0.3 }))
			animation_store.add_timeline(gsap.fromTo(backhole, { pixi: { angle: 0 } }, { pixi: { angle: 360  }, repeat: -1, ease: 'linear', duration: 20 }))
			for (const i of range(water_drops.length)) {
				animation_store.add_timeline(gsap.fromTo(water_drops[i], 
				{ alpha: 0 }, 
				{ alpha: 1, repeat: -1, yoyo: true, duration: 2, ease: 'power2.in', delay: (2-i) * 0.3 }))
			}
			bubbles.forEach(bubble => 
				animation_store.add_timeline(
					gsap.fromTo(bubble, 
						{ pixi: { y: random(height * 0.75, height * 1.2) } }, 
						{ pixi: { y: -bubble.height * 2 }, repeat: -1, duration: random(7, 25), ease: 'linear' })
			));

			/* -------------------------------- TIMELINE -------------------------------- */

			app.stage.addChild(blurbg, backhole, door, heart_container, water_drops_container, ...hearts_rest, bubbles_container)

			unsafe_global_timeline = gsap.timeline({ paused: true })
				.add('start')
				.add('blurbg-start')
				.set(bubbles_container, { alpha: 0 })
				.add(() => {
					root.setProperty(CSS_PROPERTY_COLOR_BACKGROUND, colors.black)
					root.setProperty(CSS_PROPERTY_COLOR_FONT, colors.black);
				}, '+=0.1')
				.fromTo(blurbg, { pixi: { alpha: 0 } }, { pixi: { alpha: 1 }, duration: 1 }).add('blurbg-show')
				.fromTo(blurbg, { alpha: 1 }, { alpha: 0 }).add('blurbg-out')
				.add('backhole-start')
				.add(() => {
					root.setProperty(CSS_PROPERTY_COLOR_BACKGROUND, colors.black)
					root.setProperty(CSS_PROPERTY_COLOR_FONT, colors.white);
				}, '+=0.1')
				.fromTo(backhole, { alpha: 0 }, { alpha: 1, duration: dev ? 1 : 3, delay: 1 }).add('backhole-show')
				.to(backhole, { alpha: 0, duration:  dev ? 1 : 3 }).add('backhole-out')
				.add('door-start')
				.fromTo(door, { pixi: { alpha: 0 } }, { pixi: { alpha: 1 }, duration: dev ? 1 : 4 })
				.fromTo(door, { pixi: { y: 0 } }, { pixi: { y: -door.height * 0.75 }, duration: dev ? 3 : 15 }, '<').add('door-show')
				.to(door, { alpha: 0 }).add('door-out')
				.add('heart-single-start')
				.add(() => {
					root.setProperty(CSS_PROPERTY_COLOR_BACKGROUND, colors.black)
					root.setProperty(CSS_PROPERTY_COLOR_FONT, colors.white)
				})
				.add(() => bounce_heart.repeat(-1).play())
				.fromTo(heart_container, { alpha: 0 }, { alpha: 1, duration: 2, ease: 'power2.out' }).add('heart-single-show')
				.add(() => bounce_heart.pause(), '+=0.1')
				.to(heart, { pixi: { width: HEART_SIZE, height: HEART_SIZE }, duration: 2.5, ease: 'power3.inOut' })
				.fromTo(hearts_rest, { alpha: 0 }, { alpha: 1, stagger: { amount: 0.2, from: 'random' }, duration: 0.7 }).add('heart-single-grid')
				.to([heart_container, ...hearts_rest], { alpha: 0, stagger: { amount: 0.2, from: 'random' }, duration: 0.7 })
				.call(() => {
					hearts_rest.forEach(h => h.alpha = 0)
				})
				.add('heart-single-grid-out')
				.add('water-drop-start')
				.set(hearts_rest, { alpha: 0 })
				.fromTo(water_drops_container, { alpha: 0 }, { alpha: 1 })
				.set(hearts_rest, { alpha: 0 }).add('water-drop-show')
				.to(water_drops_container, { alpha: 0 }).add('water-drop-out')
				.add('bubble-start')
				.set(bubbles_container, { alpha: 0 })
				.fromTo(bubbles_container, { alpha: 0 }, { alpha: 1, duration: 4, ease: 'power2.out' }).add('bubble-show')
				.fromTo(bubbles_container, { alpha: 1 }, { alpha: 0 }).add('bubble-out')
		}),
		fun(() => unsafe_global_timeline.tweenFromTo("blurbg-start", "blurbg-show").then()),
		component(FadeComponent, {
			html: `<img class="h-32 mx-auto" src="/deadlinealwaysexists-logo.png" alt="logo" />`,
			duration: 1000
		}),

		//"This interactive website contains self and emotional exploration."
		//"Please" 
		message(`
			<div class="p-4" style="max-width: 400px">
				<p class="mt-8 text-left" style="text-indent: 5ch;"> 
					<span style="white-space: nowrap;">เว็บนี้มีเนื้อหาเกี่ยวกับความรู้สึก</span> และมีคำถามเกี่ยวกับตัวคุณ และเหตุการณ์ในอดีต 
					<span style="white-space: nowrap;">โปรดตรวจสอบ</span>ความพร้อมด้านจิตใจของคุณก่อนกดเล่น
					หากคุณรู้สึกไม่สบายใจ สามารถกดออกได้ตลอดเวลา 
					ขอบคุณที่ให้ความสนใจโปรเจคของเรา
				</p>
				<p class="mt-8 text-left">
					*เว็บไซต์นี้ไม่ใช่แบบประเมินทางจิตวิทยาหรือโหราศาสตร์
					โปรดใช้วิจารณญาณในการเล่น
				</p>
				<p class="mt-8">
					<button class="button is-primary w-full">
						รับทราบ
					</button>
				</p>
			</div>`),
		message(`เว็บนี้ไม่มีการเก็บคำตอบของคุณ`),//"This website does not collect any types of data includes personal data."
		message(`คุณสามารถปล่อยใจสบายๆ`),//"You can free your mind..."
		message(`แล้วเล่าเรื่องราวอย่างที่เป็นตัวเองได้เลย`),//"And feel free to tell "

		//"There is no place on the Earth for me to feel me feel."
		//"This project starts from a simple sentence from mundane routine we live during covid-19."
		//"Here's the collaboration among Thai Health, ActionPlay and Deadline Always Exists."
		//"To let you feel yourself and emotions once again."
		//"This event you are going to play is a fictional event."
		//"But your feelings and emotions that happen is real."
		message(`
			<div class="p-4" style="max-width: 400px">
				<p><img class="h-32 mx-auto" src="/actionplay-logo.png" alt="logo" /></p>
				<p class="mt-8 text-2xl">" โลกนี้ไม่มีพื้นที่ให้ฉันได้รู้สึก "</p>
				<p class="mt-8 text-left" style="text-indent: 5ch;">
					โปรเจคนี้เริ่มจากประโยคง่ายๆ ที่ชีวิตประจำวันอันซ้ำซากในช่วงโควิดทำให้เราต้องพูดมันออกมา
					เราจึงจับมือกับ สสส. SYSI และ ActionPlay มา<span style="white-space: nowrap;">สร้าง</span>พื้นที่ให้ลองเล่นเป็นตัวละครต่าง ๆ สร้างสถานการณ์
					ที่สามารถเป็นพื้นที่ปล่อยความรู้สึกให้ออกมาเล่น
				</p>
				<p class="mt-8 text-left" style="text-indent: 5ch;">
					เหตุการณ์ที่เราจะให้คุณเล่นต่อจากนี้เป็นเพียงเรื่องสมมติ
					แต่ความรู้สึกของคุณที่เกิดขึ้นนั้นล้วนแล้วแต่เป็นของจริง
				</p>
				<p class="mt-8">
					<button class="button is-primary w-full">
						กดเพื่อเข้าเล่น//"click to play"
					</button>
				</p>
			</div>`),
		fun(() => howler_instance.play(ID_INTRO)),
		input(`ชื่อของคุณ`, store.name),
		fun(() => unsafe_global_timeline.tweenTo("blurbg-out").then()),
		fun(() => {
			root.setProperty(CSS_PROPERTY_COLOR_BACKGROUND, colors.black)
			root.setProperty(CSS_PROPERTY_COLOR_FONT, colors.white);
		}),
		component(FadeComponent, {
			duration: 2000,
			html: `
				illustration design by @piewpieww
			`,
		}),
		fun(async () => {
			await unsafe_global_timeline.tweenTo('backhole-show').then()
		}),
		({ name }) => message(`สวัสดี ${name}`),//"Hello"
		choice({
			label: 'วันนี้ของเธอเป็นยังไงบ้าง',//"How are you today?"
			select: ['เยี่ยมไปเลย', 'ก็ดีนะ', 'เฉยๆ แหละ', 'ก็เหมือนทุกวัน', 'ไม่ค่อยดีเท่าไหร่']//"['Amazing!','I'm great','Nothing special','Same old day']"
		}),
		message('แล้วความรู้สึกล่ะ'),//"What about your feelings?"
		input(`ความรู้สึกที่ผ่านมาบ่อยๆ<br>ช่วงนี้คืออะไร`),//"What have you been feeling recently?"
		message(`งั้นไม่ว่าช่วงนี้เธอจะเจออะไรมา`),//"No matter what you've been through,"
		message(`จับมือฉันไว้นะ`),//"Just hold my hands."
		message(`เราจะไปเล่นกัน`),//"We will let all of the stuff go and feel yourself once again."
		parallel(() => unsafe_global_timeline.tweenTo('door-show')),
		message(`คุณเดินไปข้างหน้า`),//"You step forward."
		message(`ปล่อยเท้าให้นำความคิดไป`),//"Let your feet guide you."
		message(`แล้วหยุดลงที่ประตูสีขาวบานใหญ่`),//"And stop right at this large white door"
		message(`ยังไม่เปิดมันออก`),//"Not to open it yet."
		message(`แต่ยกมือแนบไปบนบานประตู`),//"Just touch and feel it."
		fun(async () => {
			await unsafe_global_timeline.tweenTo('door-out').then()
			console.log('single >')
			await unsafe_global_timeline.tweenFromTo('heart-single-start', 'heart-single-show').then()
			console.log('single < (should not kill)')
			console.log('sleep >')
			await sleep(0.5)
			console.log('sleep <')
		}),
		message(`สัมผัสความรู้สึกที่ฝ่ามือ`),//"Feel all your feelings and emotions right at your palm."
		message(`ประตูบานนี้ให้ความรู้สึกเดียวกับ<br>สัมผัสของหัวใจที่เต้นอยู่ในอก<br>ข้างซ้ายของคุณ`),//"This door gives you the same feeling as the beating heart in your left chase."
		message(`ยังจําสัมผัสนั้นได้มั้ย`),//"Do you member this feeling?"
		message(`ถ้าไม่ได้สังเกตมันมานานแล้ว<br>ลองใช้มือแตะที่อกข้างซ้ายเบา ๆ สิ`),//"If you've not noticed it for a while, just let your hand touch your left chase softly."
		message(`รู้สึกได้มั้ย หัวใจของคุณที่เต้นอยู่`),//"Can you feel it? Your heart is beating."
		message(`ก่อนที่จะเข้าไปในห้องนี้`),//"Before you enter this room,"
		input(`เธอจําได้มั้ยว่าเธอคือใคร`),//"Do you still remember who you are?"
		input(`แล้วอะไรที่ทําให้เธอเป็นคนแบบนี้`),//"And what makes you become you."
		message(`อืม`),//"Umm..."
		message(`แล้ว ...`),//"So..."
		fun(() => unsafe_global_timeline.tweenTo("heart-single-grid").then()),
		message(`ความรู้สึกอะไรบ้างล่ะ<br>ที่ประกอบเธอให้เป็นเธอ`),//"What feelings and emotions that you are made and shaped from in order to become yourself?"
		message(`จะตอบมากกว่าหนึ่งความรู้สึกก็ได้นะ`),//"You can answer more than one."
		multiplecheckbox({
			label: `ไหน คิดว่าตัวเธอประกอบขึ้นจาก<br>ความรู้สึกอะไรบ้างล่ะ<br>(ตอบได้มากว่า 1 นะ)`,//"You are made and shaped from..."
			value: store.main_branch,
			checkboxs: shuffle([
				{ category: ID_STRONG, label: 'กลัว' },//"Fear"
				{ category: ID_STRONG, label: 'อ่อนแอ' },//"Weakness"
				{ category: ID_STRONG, label: 'กังวล' },//"Anxiety"
				{ category: ID_STRONG, label: 'ผิดหวัง' },//"Disappointment"
				{ category: ID_STRONG, label: 'เสียใจ' },//"Sadness"
				{ category: ID_STRONG, label: 'เครียด' },//"Stress"
				{ category: ID_STRONG, label: 'ทุกข์' },//"Misery"
				{ category: ID_STRONG, label: 'เปี่ยมไปด้วยความหวัง' },//"Hopefulness"
				{ category: ID_SOFT, label: 'โกรธ' },//"Fury/Anger"
				{ category: ID_SOFT, label: 'รังเกียจ' },//"Hatred"
				{ category: ID_SOFT, label: 'เข้มแข็ง' },//"Strength"
				{ category: ID_SOFT, label: 'เปี่ยมไปด้วยความรัก' },//"Full of love"
				{ category: ID_SOFT, label: 'สงบ' },//"Peace"
				{ category: ID_SOFT, label: 'กล้าหาญ' },//"Brave"
				{ category: ID_SOFT, label: 'มั่นใจ' },//"Confidence"
				{ category: ID_COOL, label: 'เบื่อ' },//"Boredom"
				{ category: ID_COOL, label: 'ตลก' },//"Humor"
				{ category: ID_COOL, label: 'สนุก' },//"Fun"
				{ category: ID_COOL, label: 'มีพลัง' },//"Powerfulness"
				{ category: ID_COOL, label: 'น้อยใจ' },//"Resentment"
				{ category: ID_LOVE, label: 'เชื่อใจ' },//"Trust"
				{ category: ID_LOVE, label: 'อ่อนโยน' },//"Tenderness"
				{ category: ID_LOVE, label: 'ภูมิใจ' },//"Pride"
				{ category: ID_LOVE, label: 'เหงา' },//"Loneliness"
				{ category: ID_LOVE, label: 'มีความสุข' }//"Happiness"
			]),
			onSelected(categories) {
				console.log('drop')
				unsafe.gsap_timeline_remove_grid_heart_element(categories.length)
			}
		}),
		sleep(2),
		fun(({ main_branch }) => {
			switch(main_branch) {
				case ID_LOVE: {
					store.youare_adj.set('เปี่ยมไปด้วยความรัก')//"Full of love" 
					store.youare_noun.set('การเปี่ยมไปด้วยความรัก')//"Full of love" 
					store.youare_role.set('นักเรียนม.ปลาย')//"The High School Student"
					break;
				}
				case ID_SOFT: {
					store.youare_adj.set('อ่อนโยน')//"Tender" 
					store.youare_noun.set('ความอ่อนโยน')//"Tenderness"
					store.youare_role.set('คนกำลังจะย้ายบ้าน')//"The Person Who's Moving"
					break;
				}
				case ID_STRONG: {
					store.youare_adj.set('เข้มแข็ง')//"Strong" 
					store.youare_noun.set('ความเข้มแข็ง')//"Strength"
					store.youare_role.set('นักกีฬาวิ่ง')//"The Runner"
					break;
				}
				case ID_COOL: {
					store.youare_adj.set('เจ๋ง')//"Cool" 
					store.youare_noun.set('ความเจ๋ง')//"Coolness"
					store.youare_role.set('นักท่องเที่ยว')//"The Explorer"
					break;
				}
				default: {
					throw new Error("branch logic error")
				}
			}
		}),
		message(`เอาล่ะ`),//"Well,"
		message(`อุ่นเครื่องกันพอแล้ว`),//"We've already warmed up."
		message(`เรากําลังจะ<br>ผลักประตูบานนี้เข้าไปกัน`),//"We're going to enter this door."
		fun(() => unsafe_global_timeline.tweenTo("heart-single-grid-out").then()),
		parallel(() => unsafe_global_timeline.tweenFromTo("door-start", "door-show")),
		message(`ก่อนเข้าลองรู้สึกถึงการเต้นของหัวใจ<br>อีกคร้ังสิ`),//"Before you enter, I want you to feel your heartbeat at once."
		message(`ใจเย็นๆ นะ ไม่ต้องรีบ`),//"Take your time, no rush."
		message(`หลังประตูบานนี้<br>เธอเป็นได้ทุกอย่างในโลกเลยคนเก่ง`),//"Love, behind this door, you can be anything you want in the world."
		message(`คุณผลักประตูเข้าไป`),//"You push the door."
		fun(() => howler_instance.pause_all()),
		fun(() => unsafe_global_timeline.tweenTo("door-out").then()),
		component(BlankComponent),
		fun(async () => {
			root.setProperty(CSS_PROPERTY_TRANSITION_DURATION, '10s')
			root.setProperty(CSS_PROPERTY_COLOR_BACKGROUND, colors.white)
			root.setProperty(CSS_PROPERTY_COLOR_FONT, colors.black)
		}),
		sleep(5),
		fun(async () => {
			root.setProperty(CSS_PROPERTY_TRANSITION_DURATION, '1s')
		}),
		message(`คุณก้าวเข้าสู่ห้องสีขาว`),//"You are entering this white room."
		message(`เป็นห้องที่ขาวที่สุด<br>ที่คุณเคยเห็น`),//"It is the whitest room you've ever seen."
		message(`ความกว้างของมันสุดลูกหูลูกตา`),//"Its vast is limitless as far as the eye can see."
		fun(() => unsafe_global_timeline.tweenFromTo("water-drop-start", "water-drop-show").then()),
		// TODO WTF heart grid is popup here
		message(`ที่นี่คือสนามเด็กเล่น`),//"This is your playground."
		message(`ในห้องนี้คุณจะเป็นใครก็ได้`),//"You can be whatever and whoever you want to be here."
		message(`รู้สึกอะไรก็ได้`),//"And, obviously, whatever you want to feel."
		message(`และขอให้คุณโอบรับทุกความรู้สึกที่เกิดขึ้น<br>ว่ามันเป็นส่วนหนึ่งของคุณ`),//"Hopefully you embrace everything you feel as it's a part of youself."
		fun(() => unsafe_global_timeline.tweenTo("bubble-show").then()),
		message(`เอาล่ะ`),//"Okay then"
		message(`ปล่อยจินตนาการไปให้เต็มที่`),//"Let go of everything in your heart."
		message(`แล้วลองเล่นดูนะ`),//"And just try to feel."
		({ youare_role }) => message(`ในห้องนี้เราจะสมมติให้คุณเป็น ${youare_role}`),//"In this room you will be roleplayed as ${youare_role}"
		({ youare_role }) => message(`ลองจินตนาการว่า ${youare_role} กําลังยืนอยู่ข้างหน้าคุณ`),//"Try to imagine ${youare_role} is standing in front of you."
		message(`คุณเห็นเขา`),//"You see them."
		message(`คุณรู้สึกถึงลมหายใจของเขา`),//"You can feel their breath."
		message(`จับมือเขา`),//"Hoding their hands."
		message(`รู้สึกถึงจังหวะการเต้นของหัวใจของเขา`),//"Feeling their heartbeat."
		fun(({ main_branch }) => howler_instance.play(main_branch, { delay: 500 })),
		fun(() => unsafe_global_timeline.tweenTo("bubble-out")),
		fun(() => animation_store.fadeout_all({ duration: 0.3 })),
		message(`แล้วคุณก็กลายเป็นเขา`),//"And in a blink, you become them."
		recursive(({ main_branch }) => main_branch), // GO TO STORY
		recursive(() => ID_ENCOURAGE), // GO TO STORY
		fun(() => animation_store.fadeout_all({ duration: 0.3 })),
		message(`ขอบคุณที่วันนี้มาเล่นด้วยกัน`),//"Thank you for coming to play with us today."
		message(`ถ้าชอบงานนี้ หรืออยากพูดคุยแลกเปลี่ยนประสบการณ์<br>สามารถเข้ามาแชร์กันได้ที่ #ห้องเล่นสีขาว<br>พวกเราจะฟังเรื่องราวของทุกคนอยู่นะคะ`),//"If you like this project and want to exchange your feelings please feel free to discuss here at #whiteplayroom, we are wholeheartedly looking forward to hearing from you."
		recursive(() => ID_RESULTCARD),
	]),
	block(ID_RESULTCARD, [
		({ main_branch }) => {
			const mapping_props = new Map([
				[ID_STRONG, { card1: '/card/card_actionplay-01.png', card2: '/card/card_actionplay-05.png' }],
				[ID_SOFT,   { card1: '/card/card_actionplay-02.png', card2: '/card/card_actionplay-06.png' }],
				[ID_COOL,   { card1: '/card/card_actionplay-03.png', card2: '/card/card_actionplay-07.png' }],
				[ID_LOVE,   { card1: '/card/card_actionplay-04.png', card2: '/card/card_actionplay-08.png' }],
			])
			return component(ResultCard, mapping_props.get(main_branch) || {});
		},
	]),

	/* ------------------------------- LOVE ROUTE ------------------------------- */

	block(ID_LOVE, [
		fun(async () => {
			await animation_store.fadeout_all({ duration: 0 })
			root.setProperty(CSS_PROPERTY_COLOR_BACKGROUND, colors.white)
			root.setProperty(CSS_PROPERTY_COLOR_FONT, colors.black)
			
			const sp1 = AnimationStore.load_sprite("/element/element-actionplay-10.png", { })
			const sp2 = AnimationStore.load_sprite("/element/element-actionplay-12.png", { scale: 0.8, positionX: -80, positionY: -100 })
			const sp3 = AnimationStore.load_sprite("/element/element-actionplay-11.png", { scale: 0.5, positionX: 100, positionY: -150 })
			const sp4 = AnimationStore.load_sprite("/element/element-actionplay-11.png", { scale: 0.5, positionX: 100, positionY: -150 })
			const sp5 = AnimationStore.load_sprite("/element/element-actionplay-12.png", { scale: 0.8, positionX: 50, positionY: 100 })

			const yellow = AnimationStore.load_sprite("/element/element-actionplay-10.png")
			const orange = AnimationStore.load_sprite("/element/element-actionplay-11.png")

			animation_store.add_timeline(gsap.to([sp1, sp2, sp3, sp4, sp5, yellow, orange], { pixi: { angle: 360 }, repeat: -1, duration: 20 }))

			app.stage.addChild(sp1)
			app.stage.addChild(sp1, sp2, sp3, sp3, sp4, sp5, yellow, orange)

			unsafe_global_timeline = gsap.timeline({ paused: true })
				.add("start")
				.fromTo(sp1, { alpha: 0 }, { alpha: 1 }).add('sp1-in')
				.fromTo(sp2, { alpha: 0 }, { alpha: 1 }).add('sp2-in')
				.fromTo(sp3, { alpha: 0 }, { alpha: 1 }).add('sp3-in')
				.to([sp2, sp3], { alpha: 0 }).add('sp23-out')
				.to(sp1, { alpha: 0 })
				.from(sp4, { alpha: 0 }).add('sp14-swap')
				.to(sp4, { alpha: 0 })
				.from(sp5, { alpha: 0 }).add('sp45-swap')
				.to(sp5, { alpha: 0 })
				.from([yellow, orange], { alpha: 0 }).add('pair-in')
				.to(yellow, { pixi: { scale: 0.9, y: 100 }, duration: 1.5 })
				.to(orange, { pixi: { scale: 0.9, y: -100 }, duration: 1.5 }, '<').add('pair-seperate')
				.to([yellow, orange], { pixi: { alpha: 0 } }).add('pair-end')
				
				.to([sp1, sp2, sp3], { alpha: 1, stagger: 1 }).add("encourage-beself")
				.to([sp2, sp3], { alpha: 0, stagger: 1 }).add("encourage-heart")
				.fromTo([sp1], { pixi: { skewY: 0 } }, { pixi: { skewY: 5 } })
				.to([sp1], { pixi: { skewY: -5 }, repeat: 4, duration: 0.3, yoyo: true, ease: 'circle' })
				.to([sp1], { pixi: { skewY: 0 } }).add("encourage-feel")
				.to([sp1, sp2, sp3], { alpha: 1 }).add("encourage-lookback")
				.to([sp1, sp2, sp3], { alpha: 0 }).add("encourage-end")
		}),
		message(`คุณเป็นนักเรียนม.ปลาย<br>ที่เคยแอบชอบเพื่อนสนิทตัวเอง`),//"You are a high school student who used to secretly like your best friend."
		fun(() => unsafe_global_timeline.tweenFromTo('start', 'sp1-in')),
		message(`ตอนม.4 คุณทั้งสองเป็นเพื่อนที่สนิทกันมาก`),//"At your first year of high school, you two were so close, closer than anything else you've experienced."
		message(`<div style="white-space: nowrap;">แต่ในระหว่างความสนิทนั้น<div>`),//"But in that intimacy,"
		fun(() => unsafe_global_timeline.tweenTo('sp2-in')),
		message(`มันมีความรู้สึกบางอย่าง<br>ที่คั่นกลางระหว่างคุณและเขา`),//"There is a tiny gap of strange feeling that parts two of you."
		message(`บางทีเขาอาจจะรู้สึก`),//"Maybe that person might feel something."
		message(`บางทีเขาอาจจะไม่`),//"Or maybe not"
		
		fun(() => unsafe_global_timeline.tweenTo('sp3-in')),
		message(`แต่ที่แน่ ๆ คือใจคุณรู้สึก`),//"But the thing is that your heart truly feels something."
		message(`เพียงแต่ว่าตัวคุณเองไม่กล้าถามมันออกไป`),//"You are just too scared to ask it out loud."
		
		fun(() => unsafe_global_timeline.tweenTo('sp23-out')),
		message(`มันเป็นประสบการณ์แอบรักครั้งเดียวในรั้วมัธยม`),//"This is the only one-sided love story you've experienced during high school."
		message(`เรื่องนี้มันจบโดยที่คุณก็ไม่รู้เลย<br>ว่าตอนนั้นเขาก็ชอบคุณกลับ<br>หรือเปล่า`),//"It ends without you knowing wheter the person ever likes you back."
		message(`แต่คุณก็พอใจแล้วที่มันเป็นไปแบบนั้น`),//"However, you feel like it's fine like this anyway."
		message(`พอขึ้นม.5 คุณทั้งสองก็แยกกันไปอยู่คนละห้อง`),//"You two were aparted into different classes in the second year."
		message(`แยกกันไปมีแฟนของตัวเอง`),//"Having lovers of themselves."
		message(`มีความรักที่ไม่ต้องแอบของตัวเอง`),//"And love that is no longer one-sided."
		
		fun(() => unsafe_global_timeline.tweenTo('sp14-swap')),
		message(`จนเวลาล่วงเลยมาจนถึงวันปัจฉิม`),//"Time passes by until it's the last day of your high school life."
		message(`ใต้ป้ายผ้าสีขาวที่เขียนว่าเพื่อนกันตลอดไป`),//"Under the written "Friend Forever"."
		message(`ท่ามกลางบรรยากาศการอำลาและความทรงจำ`),//"In the midst of tear, good bye words and those memories that is going to fade away."
		fun(() => unsafe_global_timeline.tweenTo('sp45-swap')),
		message(`คุณบังเอิญเจอเขา`),//"You accidentally meet that person."
		message(`คนที่เป็นการแอบรักครั้งแรกและครั้งเดียวในรั้วมัธยมของคุณ`),//"The first and last one whom you secretly love."
		message(`ตอนนี้คุณไม่ได้รู้สึกอะไรกับเขาแล้ว`),//"Now you feel nothing anymore."
		message(`คุณมีแค่คำถามที่อยากรู้`),//"You have one question that you've always wanted to know."
		message(`แต่ไม่ได้อยากเป็นอะไรกัน`),//"Even though we've never been together."
		message(`ในวันสุดท้ายที่ได้เจอกันก่อนจะแยกย้ายกันไปโต`),//"In the last day before we really part."
		input(`คุณอยากพูดอะไรกับเขา`),//"You want to say something to the person."
		fun(() => unsafe_global_timeline.tweenTo('pair-in')),
		message(`บรรยากาศในโรงเรียนเงียบลงอย่างน่าใจหาย`),//"All the surroundings become suddenly quiet."
		message(`คุณทั้งสองยิ้มให้กัน`),//"You two wave and smile at each other."
		message(`เก็บกันและกันไว้เป็นความทรงจำดี ๆ`),//"Just keep each others in a pocket of memory."
		fun(() => unsafe_global_timeline.tweenTo('pair-seperate')),
		message(`แล้วคุณก็เดินจากเขาไป`),//"Then you walk through."
		message(`การแอบรักนี่แปลกดีนะ`),//"One-sided love is so strage."
		message(`เป็นความรักที่ไร้เดียงสา`),//"It's so innocent."
		message(`เป็นความรักที่ให้ภายใต้คำว่าแอบ`),//"It's the love we give under the condition 'secretly'."
		message(`แค่เราได้แอบมีความสุขอยู่ฝ่ายเดียวก็พอใจ`),//"It's totally fine just being able to secretly enjoy and smile to yourself."
		message(`แค่ได้ให้ความหวังดี<br>และมีความทรงจำดี ๆ ร่วมกัน`),//"Just being able to see that person happy and share those memories, it is totally fine."
		message(`...`),
		message(`ไม่รู้โตกว่านี้ยังจะมีความรักที่ไร้เดียงสาแบบนี้ได้มั้ย`),//"You have zero idea whether the world outside will let you experience this kind of innocent love once again."
		message(`แต่ระหว่างที่กำลังจะก้าวขาออกจากรั้วโรงเรียน`),//"While you're leaving this place,"
		message(`ในวินาทีสุดท้ายของสถานะนักเรียนม.ปลาย`),//"Before your last moment of being a high schooler,"
		input(`คุณบอกลาการแอบรักครั้งแรก<br>และครั้งสุดท้ายในวัยเรียนของคุณในใจ`),//"You speak to yourself saying good bye to your first and last one-sided love."
		fun(() => {
			store.youare_adj.set('เปี่ยมไปด้วยความรัก');//"Full of love"
			store.youare_noun.set('การเปี่ยมไปด้วยความรัก');//"Full of love"
		}),
		fun(() => unsafe_global_timeline.tweenTo('skew-orange')),
		fun(() => unsafe_global_timeline.tweenTo('orange-solo')),
		fun(() => unsafe_global_timeline.tweenTo('yellow-solo')),
		fun(() => unsafe_global_timeline.tweenTo('yellow-solo-out')),
	]),
	fun(() => unsafe_global_timeline.tweenTo('pair-end')),

	/* ------------------------------- SOFT ROUTE ------------------------------- */

	block(ID_SOFT, [
		fun(async () => {
			await animation_store.fadeout_all({ duration: 0 })
			const donut = AnimationStore.load_sprite("/element/element-actionplay-05.png", { scale: 1.0, y: -100 })
			const pink1 = AnimationStore.load_sprite("/element/element-actionplay-06.png", { scale: 0.4, y: -10, x: 200 })
			const pink2 = AnimationStore.load_sprite("/element/element-actionplay-06.png", { scale: 0.2, y: -120, x: -200 })
			const brown = AnimationStore.load_sprite("/element/element-actionplay-04.png", { scale: 2.8, y: 120, x: 0 })
			console.log(brown.scale, 'brow poisition not set') // bug brown posutuib
			const donutmini = AnimationStore.load_sprite("/element/element-actionplay-05.png", { scale: 0.3, y: 150 })
			const pinkmini = AnimationStore.load_sprite("/element/element-actionplay-06.png", { scale: 0.4, y: 0, x: 0 })
			const orbit = new PIXI.Container()
			orbit.position.set(0, -200)
			orbit.addChild(donutmini, pinkmini)

			animation_store.add_timeline(gsap.fromTo(orbit, { pixi: { angle: 0 } }, { pixi: { angle: 360 }, duration: 20, repeat: -1 }))
			animation_store.add_timeline(gsap.fromTo(donutmini, { pixi: { angle: 0 } }, { pixi: { angle: -360 }, duration: 18, repeat: -1 }))
			animation_store.add_timeline(gsap.fromTo([brown, pink1, pink2], { pixi: { angle: 0 } }, { pixi: { angle: -360 }, duration: 14, repeat: -1 }))

			app.stage.addChild(donut, pink1, pink2, brown, orbit)
			unsafe_global_timeline = gsap.timeline({ paused: true })
				.add("start")
				.from([donut, pink1, pink2, brown], { alpha: 0, stagger: 0.5 })
				.set([orbit, donutmini, pinkmini], { alpha: 0 })
				.add("show")
				.to([pink1, pink2, brown], { alpha: 0 })
				.to([donut], { pixi: { positionX: 0, positionY: 0 }, duration: 1.5 }, '<')
				.add("single")
				.to([donut], { pixi: { scale: 0.8 }, duration: 0.7 })
				.add("single-small")
				.to([donut], { pixi: { skewX: 5, duration: 0.4 } })
				.to([donut], { pixi: { skewX: -5 }, yoyo: true, repeat: 5, duration: 0.3, yoyoEase: 'circle' })
				.to([donut], { pixi: { skewX: 0, duration: 0.5 } })
				.add("single-skew")
				.fromTo([orbit, pinkmini], { alpha: 0 }, { alpha: 1 })
				.add("astro-pair")
				.to(donut, { pixi: { alpha: 0 }, duration: 1 })
				.add("astro-sub")
				.to(pinkmini, { pixi: { scale: 0.47 }, yoyo: true, repeat: 5, duration: 0.3, yoyoEase: 'circle' })
				.add("astro-sub-bounce")
				.fromTo(donutmini, { alpha: 0 }, { alpha: 1 })
				.add("astro-mini")
				.to(donutmini, { pixi: { alpha: 0 } })
				.to(orbit, { pixi: { positionY: 0 } }, '<')
				.add("astro-bounce")
				.to(pinkmini, { pixi: { scale: 0.6 } })
				.add("encourage-beself")
				.add("encourage-heart")
				.fromTo(donut, { pixi: { x: 0, y: 0, scale: 0.8 } }, { pixi: { x: 0, y: -300, alpha: 1 } })
				.fromTo(brown, { pixi: { x: 0, y: 0, scale: 0.4 } }, { pixi: { x: 0, y:  250, alpha: 1 } }, '<')
				.add("encourage-lookback")
				.to([donut, pink1, pink2, brown, orbit], { pixi: { alpha: 0 } })
				.add("encourage-end")
		}),
		fun(() => unsafe_global_timeline.tweenTo("show").then()),
		message(`คุณเป็นใครซักคนที่กําลังจะย้ายบ้าน`),//"You are someone who's packing up the house and moving." 
		message(`มีความทรงจํามากมายในบ้านหลังนี้`),//"There are memories in every corner of the house,"
		message(`แต่คุณคงไม่สามารถขนไปด้วยทั้งหมดได้`),//"Those memories you wish you could pack and take them with you."
		message(`ระหว่างที่คุณเก็บของอยู่น้ัน`),//"While you're packing up,"
		fun(() => unsafe_global_timeline.tweenTo("single").then()),
		message(`คุณเหลือบไปเห็นของเล่นวัยเด็ก<br>ชิ้นหนึ่งที่คุณผูกพันมาก`),//"You take a glance and see your childhood toy."
		message(`คุณหยิบมันขึ้นมา`),//"You pick it up."
		message(`สัมผัสมัน`),//"Touch it."
		message(`ค่อย ๆ ลูบอย่างอ่อนโยน`),//"And gently feel it in your hands."
		message(`ถ้าของชิ้นนี้มองอยู่`),//"If the toy was able to withness your life."
		message(`มันคงเห็นการเติบโตของคุณมาตลอด`),//"It would see every step of you growing up."
		input(`คุณคิดว่ามีความทรงจําอะไรบ้าง<br>ที่ของชิ้นนี้ผ่านมากับคุณ`),//"While reminiscing what memories you've shared and been through together."
		fun(() => unsafe_global_timeline.tweenTo("single-small").then()),
		message(`คุณกอดมันแนบอก`),//"You hug it so tightly."
		fun(() => unsafe_global_timeline.tweenTo("single-skew").then()),
		message(`ต่อให้ผูกพันแค่ไหนก็คงเอาไปด้วยไม่ได้`),//"No matter how much you were close and loved this toy, you wouldn't be able to take it with you."
		message(`เมื่อมองออกไปนอกหน้าต่าง`),//"Looking out through the window,"
		message(`ข้างนอกนั่นมีเด็กน่าสงสาร<br>ที่ต้องทนเหงาอยู่คนเดียว`),//"You see a poor child sitting and look so lonely."
		message(`บางทีของชิ้นนี้อาจเป็นเพื่อน<br>แก้เหงาให้กับเขาได้`),//"Perhaps this toy can lessen the child's loneliness."
		message(`คุณจึงเดินออกไปนอกตัวบ้าน`),//"You step outside."
		fun(() => unsafe_global_timeline.tweenTo("astro-pair").then()),
		message(`หยุดยืนอยู่ที่หน้าเด็กคนนั้น`),//"Stop right at this child."
		input(`แล้วกล่าวบอกลาของชิ้นนี้ในใจ`),//"And then say good bye to your beloved toy."
		fun(() => unsafe_global_timeline.tweenTo("astro-sub").then()),
		message(`คุณยื่นมันให้กับเด็กน้อย`),//"You hand it to the child."
		message(`เด็กคนนั้นรับไปด้วยความยินดี`),//"The child receives and beams with happiness."
		fun(() => unsafe_global_timeline.tweenTo("astro-sub-bounce").then()),
		message(`ยิ้มกว้างที่สุดเท่าที่เด็กคนหนึ่งจะยิ้มได้`),//"And smiles so widely."
		fun(() => unsafe_global_timeline.tweenTo("astro-mini").then()),
		message(`“นี่คือของเล่นชิ้นแรกของหนู”`),//""This is the first toy I've ever received in my life.""
		message(`เด็กน้อยลูบเจ้าของเล่นด้วยความทะนุถนอม`),//"A child says as he/she gently strokes the toy."
		message(`แล้วเงยหน้าขึ้นมองคุณ`),//"Then he/she looks up to you."
		input(`คุณคิดว่าดวงตาคู่นั้น<br>สะท้อนความรู้สึกอะไร`),//"What do you feel while you catch that pair of eyes?"
		component(BlankComponent),
		fun(() => unsafe_global_timeline.tweenTo("astro-bounce").then()),
		fun(() => {
			store.youare_adj.set('อ่อนโยน');
			store.youare_noun.set('ความอ่อนโยน');
		}),
	]),

	/* ------------------------------ STRONG ROUTE ------------------------------ */

	block(ID_STRONG, [
		fun(async () => {

			/* ----------------------------- PREPARES SPRITE ---------------------------- */

			const trio_container = new PIXI.Container()
			const bigblue = AnimationStore.load_sprite('/element/element-actionplay-08.png')
			const smallblue1 = AnimationStore.load_sprite('/element/element-actionplay-07.png', { scale: 0.3, x: -150, y: -100 })
			const smallblue2 = AnimationStore.load_sprite('/element/element-actionplay-07.png', { scale: 0.3, x: 180, y: 140 })
			animation_store.add_timeline(gsap.to([bigblue, smallblue1, smallblue2], { pixi: { angle: 360 }, duration: 20, repeat: -1 }))
			trio_container.addChild(bigblue, smallblue1, smallblue2)
			
			const seven_container = new PIXI.Container()
			range(-3, 3+1).forEach(offset => {
				seven_container.addChild(AnimationStore.load_sprite('/element/element-actionplay-08.png', { scale: 0.2, x: 0, y: 70 * offset }))
			})

			const square_container = new PIXI.Container();
			([[-1, 0], [1, 0], [0, 1], [0, -1]]).forEach(([dx, dy]) => {
				const offset = 100
				square_container.addChild(AnimationStore.load_sprite('/element/element-actionplay-08.png', { scale: 0.7, x: offset * dx, y: offset * dy }))
			})

			const single_center = AnimationStore.load_sprite('/element/element-actionplay-09.png')
			animation_store.add_timeline(gsap.to(single_center, { pixi: { angle: 360 }, duration: 20, repeat: -1 }))

			const bubble_container = new PIXI.Container()
			for (const i of range(10)) {
				const textures = [
					'/element/element-actionplay-07.png',
					'/element/element-actionplay-08.png',
					'/element/element-actionplay-09.png',
				]
				const { width, height } = app.screen
				const sp = AnimationStore.load_sprite(sample(textures), { scale: random(0.3, 0.8), x: random(-width / 2, width / 2) })
				animation_store.add_timeline(gsap.fromTo(sp, 
					{ pixi: { y: height * random(0.75, 0.90) } }, 
					{ pixi: { y: height * random(-0.75, -0.90) }, repeat: -1, duration: random(10, 25) } )
				)
				bubble_container.addChild(sp)
			}

			app.stage.addChild(trio_container, seven_container, square_container, single_center, bubble_container)

			/* ------------------------------ GSAP TIMELINE ----------------------------- */

			unsafe_global_timeline = gsap.timeline({ paused: true })
				.add("start")
				.from([bigblue], { alpha: 0 }).add("bigblue")
				.from([smallblue1], { alpha: 0, duration: 1 }).add("smallblue1")
				.from([smallblue2], { alpha: 0, duration: 1 }).add("smallblue2")
				.to([smallblue1], { pixi: { alpha: 0 }, duration: 1 }).add("smallblue-out-1")
				.to([smallblue2], { pixi: { alpha: 0 }, duration: 1 }).add("smallblue-out-2")
				.to([bigblue], { alpha: 0 }).add("bigblue-out")
				.from([...seven_container.children], { alpha: 0, stagger: { amount: 0.5, from: 'center' } }).add("seven-in")
				.to([...seven_container.children], { alpha: 0, stagger: { amount: 0.5, from: 'start' } }).add("seven-out")
				.from(single_center, { alpha: 0 }).add("onewithwind-in")
				.to(single_center, { pixi: { alpha: 0, y: "-=400", scale: 1.5 }, duration: 15 }).add("onewithwind-out")
				.from([...square_container.children], { alpha: 0, stagger: { amount: 0.2, from: 'random' } }).add("square-in")
				.to([...square_container.children], { pixi: { x: 0, y: 0 }, duration: 1 }).add("square-merge")
				.to([...square_container.children], { alpha: 0 }).add("square-out")
				.to(single_center, { alpha: 1 }).add("encourage-beself").add("encourage-heart")
				.to(single_center, { alpha: 0 })
				.from(bubble_container, { alpha: 0 }).add("encourage-feel")
				.to(bubble_container, { alpha: 0 }).add("encourage-end")
		}),
		fun(() => unsafe_global_timeline.tweenFromTo("start", "bigblue")),
		message(`คุณเป็นนักกีฬาวิ่งที่ไม่เคยชนะ`),//"You are the runner who never wins."
		message(`ปีแล้วปีเล่าที่สมัครเข้าแข่ง`),//"Years after years of attempt and effort."
		message(`มันไม่เคยเป็นวันของคุณเลย`),//"It has never been your day,"
		message(`คุณไม่เคยได้ชัยชนะกลับมาเลย`),//"You never win a race for once."
		message(`ทุกเช้าคุณจะตื่นมาซ้อมวิ่ง`),//"You wake up to practise every morning."
		message(`และทุกเย็นคุณก็จะกลับมาซ้อมวิ่ง`),//"And also come back to practise again every evening."
		fun(() => unsafe_global_timeline.tweenTo("smallblue1")),
		message(`พยายามอย่างหนักในทุก ๆ วัน`),//"Try so hard every single day."
		fun(() => unsafe_global_timeline.tweenTo("smallblue2")),
		message(`และโดนดูถูกอย่างหนักในทุก ๆ วัน`),//"While being looked down and insulted."
		message(`หลายคนบอกว่าคุณควรพอ`),//"Everyone says you should stop."
		message(`หลายคนบอกว่าคุณควรหยุด`),//"They say it's enough for you to try."
		parallel(() => unsafe_global_timeline.tweenTo("smallblue-out-1")),
		message(`เปลืองเวลาเปล่า`),//"Such a waste of time."
		parallel(() => unsafe_global_timeline.tweenTo("smallblue-out-2")),
		message(`เสียเวลาเปล่า`),
		message(`เอาเวลาไปทําอย่างอื่นดีกว่า`),//"They say you might as well do something else."
		message(`แต่คุณก็ทําต่อไป`),//"Yet you still keep going on"
		fun(() => unsafe_global_timeline.tweenTo("bigblue-out")),
		message(`อดทนต่อไป`),//"Hold on and keep going."
		fun(() => unsafe_global_timeline.tweenTo("seven-in")),
		message(`จนเวลาผ่านไป 7 ปี`),//"7 years pass by."
		message(`วันนี้เป็นวันที่คุณลงแข่งอีกครั้ง`),//"You are in the race again."
		message(`คุณเตรียมพร้อมที่จุดออกตัว`),//"You are ready at the starting line to set off."
		message(`สายตามองไปข้างหน้า<br>มองไม่เห็นอะไรแล้วนอกจากทางที่ต้องวิ่งไป`),//"Eyes you see nothing but the route to race."
		message(`ปัง !`),//"Bam!"
		message(`สัญญาณออกตัวดังขึ้น`),//"The signal went off as it is the sign to start running again."
		fun(() => unsafe_global_timeline.tweenTo("seven-out")),
		message(`คุณออกวิ่งสุดตัว`),//"You "
		fun(() => unsafe_global_timeline.tweenTo("onewithwind-in")),
		message(`วิ่งจนรู้สึกเป็นหนึ่งเดียวกับสายลม`),//"Run as fast as you feel the unity with the wind."
		message(`วิ่งผ่านทุกคนที่เคยดูถูกคุณ`),//""
		parallel(() => unsafe_global_timeline.tweenTo("onewithwind-out")),
		message(`วิ่งผ่านทุกความอ่อนแอที่เคยรู้สึกในแต่ละวัน`),
		message(`คุณไม่คิดอะไรเลยคุณแค่วิ่ง`),//"Nothing in your head right now but run."
		message(`วิ่งให้เร็วที่สุดเท่าที่จะเร็วได้`),//"Run as fast as you can."
		message(`คุณวิ่งนำทุกคน`),//"Now you are ahead everybody."
		message(`แล้วในมิลลิเมตรสุดท้ายก่อนที่เท้าคุณจะแตะเส้นชัย`),//"At the last millimeter before you reach the finish line,"
		input(`ในหัวคุณมีคำพูดอะไรปรากฎขึ้นมา`),//"Your says something"
		fun(() => unsafe_global_timeline.tweenTo("square-in")),
		message(`คุณชนะ`),//"You win."
		message(`เป็นชัยชนะที่คุณรอมานาน`),//"It is the long awaited victory you've been waiting for."
		message(`ความพยายามของคุณสัมฤทธิ์ผล`),//"Your effort wins."
		message(`คุณมาตรงนี้ได้ไม่ใช่เพราะใคร`),//"You've come this far because of no one,"
		fun(() => unsafe_global_timeline.tweenTo("square-merge")),
		message(`แต่เพราะตัวเอง`),//"But you, It's only you."
		input(`อยากให้กล่าวขอบคุณตัวเอง<br>กับความพยายามตลอด 7 ปีที่ผ่านมาหน่อยสิ`),//"Say something to yourself after all these 7 years of effort."
		component(BlankComponent),
		fun(() => unsafe_global_timeline.tweenTo("square-out")),
		fun(() => {
			store.youare_adj.set('เข้มแข็ง');
			store.youare_noun.set('ความเข้มแข็ง');
		})
	]),

	/* ------------------------------- COOL ROUTE ------------------------------- */

	block(ID_COOL, [
		fun(() => {
			const url = {
				green: "/element/element-actionplay-01.png",
				orange: "/element/element-actionplay-03.png",
				pink: "/element/element-actionplay-02.png"
			}
			const center_green = AnimationStore.load_sprite(url.green,  { scale: 1.0 })
			const center_orang = AnimationStore.load_sprite(url.orange, { scale: 1.0 })
			const center_pinks = AnimationStore.load_sprite(url.pink,   { scale: 1.0 })
			const bubbles = [
				AnimationStore.load_sprite(url.orange, { scale: 0.5, x: -140, y: -200 }),
				AnimationStore.load_sprite(url.pink,   { scale: 0.47, x: 140,  y: 150 }),

				AnimationStore.load_sprite(url.pink,   { scale: 0.2, x: -30,  y: -300 }),
				AnimationStore.load_sprite(url.green,  { scale: 0.4, x: 150,  y: 250 }),
				AnimationStore.load_sprite(url.orange, { scale: 0.3, x: -150, y: 300 }),
				AnimationStore.load_sprite(url.green,  { scale: 0.7, x: 200,  y: -330 }),
			]
			for (const c of [center_green, center_orang, center_pinks, ...bubbles]) {
				animation_store.add_timeline(gsap.fromTo(c, { pixi: { angle: 0} }, { pixi: { angle: 360 }, duration: random(20, 25), repeat: -1 }))
			}

			const bubble_float = new PIXI.Container()
			for (let _ in range(10)) {
				const { width, height } = app.screen
				const ball = AnimationStore.load_sprite(sample(values(url)), { alpha: 0.8, scale: random(0.2, 0.5), x: random(-width/2, width/2) })
				animation_store.add_timeline(gsap.fromTo(ball,
					{ pixi: { y: height * random(0.7, 0.9), angle: 0 } },
					{ pixi: { y: height * random(-0.7, -0.9), angle: random(1000, 2000) }, duration: random(10, 25), repeat: -1 }
				))
				bubble_float.addChild(ball)
			}


			app.stage.removeChildren()
			app.stage.addChild(center_green, center_orang, center_pinks, ...bubbles, bubble_float)

			// prettier-ignore
			unsafe_global_timeline = gsap.timeline({ paused: true })
				.add("start")
				.set([center_green, center_orang, center_pinks, ...bubbles, bubble_float], { pixi: { alpha: 0 } })
				.fromTo(center_green, { pixi: { alpha: 0 } }, { pixi: { alpha: 1 } }).add("center-1")
				.fromTo(bubbles[0],   { pixi: { alpha: 0 } }, { pixi: { alpha: 1 } }).add("slave-1")
				.fromTo(bubbles[1],   { pixi: { alpha: 0 } }, { pixi: { alpha: 1 } }).add("slave-2")
				.fromTo([bubbles[3], bubbles[4], bubbles[5]], { pixi: { alpha: 0 } }, { pixi: { alpha: 1 }, stagger: 0.8 }).add("slave-3")
				.fromTo(bubbles,      { pixi: { alpha: 1 } }, { pixi: { alpha: 0 } }).add("center-2")
				.fromTo(center_green, { pixi: { alpha: 1 } }, { pixi: { alpha: 0 } }).add("center-2-out")
				.fromTo(center_orang, { pixi: { alpha: 0 } }, { pixi: { alpha: 1 } }).add("center-3")
				.fromTo(center_orang, { pixi: { alpha: 1 } }, { pixi: { alpha: 0 } }).add("center-3-out")
				.fromTo(center_green, { pixi: { alpha: 0 } }, { pixi: { alpha: 1 } }).add("center-4")
				.fromTo(center_orang, { pixi: { alpha: 1 } }, { pixi: { alpha: 0 } }).add("center-4-out")
				// .fromTo(center_pinks, { pixi: { alpha: 0 } }, { pixi: { alpha: 1, scale: 0.6 } }).add("pop-1")
				// .fromTo(center_pinks, { pixi: { alpha: 0 } }, { pixi: { alpha: 1, scale: 0.9 } }).add("pop-2")
				// .fromTo(center_pinks, { pixi: { alpha: 1 } }, { pixi: { alpha: 0 } }).add("pop-out")
				.fromTo(center_green, { pixi: { alpha: 0 } }, { pixi: { alpha: 1 } }).add("center-5")
				.fromTo(center_green, { pixi: { alpha: 1 } }, { pixi: { alpha: 0 } }).add("center-5-out")
				.fromTo(center_pinks, { pixi: { alpha: 0, scale: 0.4 } }, { pixi: { alpha: 1, scale: 1.2 }, duration: 5 }).add("encourage-beself")
				.to    (center_pinks, { pixi: { scale: 1 }, yoyo: true, repeat: 4, duration: 0.3, yoyoEase: 'circle' }).add("encourage-heart")
				.to    (center_pinks, { pixi: { alpha: 0 } }).add("encourage-feel")
				.fromTo(bubble_float, { pixi: { alpha: 0 } }, { pixi: { alpha: 1 } }).add("encourage-lookback")
				.to    (bubble_float, { pixi: { alpha: 0 } }).add("encourage-end")

		}),
		// TODO
		fun(() => unsafe_global_timeline.tweenTo("center-1").then()),
		message(`คุณเป็นนักท่องเที่ยวธรรมดา`),//"You are ordinary wanderer."
		message(`ที่ไม่รู้ว่าความเบื่อหรืออะไรดลใจ`),//""
		message(`ทำให้คุณสมัครคอร์สโดดร่ม`),//"You register parachute."
		message(`พาตัวเองมาอยู่บนฟ้า`),//"Taking yourself into the sky."
		message(`ท่ามกลางความสูงนับพันฟุต`),//"Among thousands feets of height."
		message(`วินาทีที่กำลังจะต้องกระโดดออกจากยาน`),//"The moment that you are going to jump."
		message(`คุณรู้สึกได้เลยว่าขาคุณสั่น`),//"You can feel your legs trembling."
		fun(() => unsafe_global_timeline.tweenTo("slave-1").then()),
		message(`ในหัวคุณมีความเป็นไปได้แย่ ๆ<br>ผุดขึ้นมาเต็มไปหมด`),//"Your head starts to think every bad possible idea that can happen."
		fun(() => unsafe_global_timeline.tweenTo("slave-2").then()),
		message(`ถ้าสมมติร่มไม่กางล่ะ`),//"What if the parachute doesn't work?"
		parallel(() => unsafe_global_timeline.tweenTo("slave-3").then()),
		message(`ถ้าสมมติฉันกลัวจนฉี่ราด`),//"What if I'm so scared that I wet my pants?"
		message(`ถ้าสมมติ ถ้าสมมติ เต็มไปหมด`),//"Plenty of what ifs start to hit you."
		message(`แต่มาถึงตรงนี้แล้วยังไงก็ต้องโดด`),//""
		message(`ก็แค่ต้องโดดเท่านั้น`),//""
		parallel(() => unsafe_global_timeline.tweenTo("center-2").then()),
		message(`เอาวะ !`),
		message(`คุณกลั้นใจโดดลงไป`),
		message(`วินาทีที่ร่างกายปะทะกับความเคว้งคว้าง`),//"As your body hit the air in the middle of nowhere."
		parallel(() => unsafe_global_timeline.tweenTo("center-2-out").then()),
		input(`วินาทีนั้นความคิด<br>ที่ผุดขึ้นมาในหัวคุณคืออะไร`),//""
		message(`คุณได้ยินสียงร่มถูกกระชากออก`),
		parallel(() => unsafe_global_timeline.tweenTo("center-3").then()),
		message(`เมื่อลืมตาขึ้น`),
		message(`คุณสัมผัสได้ถึงลมที่พัดผ่านหน้า`),
		message(`ตัวคุณลอยได้อย่างปลอดภัยกลางอากาศ`),//""
		parallel(() => unsafe_global_timeline.tweenTo("center-3-out").then()),
		message(`เมื่อมองลงไปด้านล่าง<br>โลกทั้งใบเปลี่ยนไปจากมุมสูง`),
		parallel(() => unsafe_global_timeline.tweenTo("center-4").then()),
		message(`เส้นขอบฟ้าอยู่ตรงหน้าคุณ`),//"the skyline where the earth and sky meet is right in front of you."
		// parallel(() => unsafe_global_timeline.tweenTo("center-4-out").then()),
		message(`ทุกอย่างอยู่ใต้เท้าคุณ`),//"Everything is under your feet."
		parallel(() => unsafe_global_timeline.tweenTo("center-5").then()),
		message(`วินาทีนั้น`),//"That moment,"
		message(`คุณรู้สึกเป็นอิสระจากโลกทั้งใบ`),//"You feel free, free from anything in the world."
		message(`ปากคุณขยับ`),//"Your mouth moves"
		message(`ตะโกนประโยคหนึ่งออกมา`),//"As it shouts a sentence."
		input(`ประโยคนั้นคืออะไร`),//"What sentence do you shout?"
		parallel(() => unsafe_global_timeline.tweenTo("center-5-out").then()),
		fun(() => {
			store.youare_adj.set('เจ๋ง');
			store.youare_noun.set('ความเจ๋ง');
		})
	]),

	/* -------------------------- ENCOURAGE ALL ENDING -------------------------- */

	block(ID_ENCOURAGE, [
		fun(({ youare_adj, youare_noun }) => {
			if (!youare_adj) alert('expect youare_adj too set');
			if (!youare_noun) alert('expect youare_noun too set');
			if (!youare_adj) throw new Error('expect youare_adj too set');
			if (!youare_noun) throw new Error('expect youare_noun too set');
		}),
		message(`เก่งมาก`),//"Well done."
		parallel(() => unsafe_global_timeline.tweenTo("encourage-beself")),
		message(`ตอนนี้ให้คุณกลับมาเป็นตัวเอง`),//"Now I want you to come back to yourself,"
		message(`มาหายใจในแบบของตัวเอง`),//"Take a deep breath."
		parallel(() => unsafe_global_timeline.tweenTo("encourage-heart")),
		message(`สัมผัสการเต้นของหัวใจตัวเอง`),//"And feel your heartbeat."
		message(`สัมผัสของหัวใจคุณตอนนี้มีอะไรเปลี่ยนไปมั้ย`),//"Is there any differences from "
		message(`จังหวะการเต้นของมันเปลี่ยนไป<br>หรือมีความรู้สึกอะไร<br>อุ่นวาบอยู่ข้างในหรือเปล่า`),
		parallel(() => unsafe_global_timeline.tweenTo("encourage-feel")),
		input(`หลังจากคุณได้ลองเล่นเป็นเขา<br>คุณรู้สึกยังไงบ้าง`),//""
		message(`อืม ...`),//"Umm..."
		message(`นี่รู้มั้ย`),//"Hey you know what."
		message(`ถึงเหตุการณ์ที่เกิดขึ้นนี้จะเป็นเรื่องสมมติ`),//"Even though what you've just experienced is not real,"
		message(`แต่ความรู้สึกที่เกิดขึ้น<br>มันเป็นเรื่องจริงนะ`),//"But your feelings and emotions are real."
		({ name, youare_adj }) => message(`จริง ๆ แล้ว${name}ก็เป็นคนที่${youare_adj}<br>เหมือนกันนะ`),//"Actually, you are"
		choice3(`เคยมีคนบอกเธอแบบนี้หรือเปล่า `),//"Have anyone told you this before?"
		choice3(`แล้วตัวเธอเองล่ะ เคยบอกตัวเองแบบนี้มั้ย`, store.ever_tell_yourself),//"What about you, Have you ever told this to yourself?"
		({ ever_tell_yourself, name, youare_noun, youare_adj }) => {
			switch (ever_tell_yourself) {
				case 'เคยสิ': //"Of course."
					return [
						input(`เล่าเรื่องนั้นให้ฉันฟังหน่อยสิ`),//""
						parallel(() => unsafe_global_timeline.tweenTo("encourage-lookback")),
						message(`ดีจัง`),
						message(`ขอบคุณที่เติบโตมาอย่างดีนะ`),//"Thank you for "
						message(`ขอให้${name}ได้เติบโตต่อไป โดยสามารถรักษาความ${youare_adj} แบบนี้เอาไว้ได้นะ`),
					];
				case 'ก็มีบ้าง':
				case 'ไม่เคยเลย':
					return [
						message(`อืม ....`),
						parallel(() => unsafe_global_timeline.tweenTo("encourage-lookback")),
						message(`งั้นลองมองย้อนกลับไปในอดีต`),
						choice2(`มีเหตุการณ์ไหนมั้ยที่สะท้อน${youare_noun}ของเธอ`, {
							ก็มีนะ: [
								input(`เล่าเรื่องนั้นให้ฉันฟังหน่อยสิ`),
								message(`ดีจังนะ`),
								message(`ที่ผ่านมาไม่รู้ว่าเธอมองข้ามมุมนี้ของตัวเองอยู่หรือเปล่า`),
								message(`แต่ถ้าเป็นไปได้ก็อนุญาตให้${youare_noun} ได้ออกมาโลดแล่นบ้างนะ`),
								message(`เพราะ${name}น่ะ เป็นคนที่${youare_adj}จริงๆ`)
							],
							ไม่มีเลย: [
								message(`ฉันว่าเธออาจจะมองข้ามมันไป`),
								message(`หลังจากนี้ลองสังเกตตัวเองดูมั้ย`),
								message(`เขาว่ากันว่า ถ้ามองหา จะมองเห็นนะ`),
								message(`เพราะ ${name}น่ะ เป็นคนที่${youare_adj}จริงๆ`)
							]
						})
					]
				default: {
					alert('error: ever_tell_yourself not match')
					throw new Error('ever_tell_yourself not match')
				}
			}
		},
		fun(() => unsafe_global_timeline.tweenTo("encourage-end")),
	])
];

/* ------------------------ RECURSIVE TURING MACHINE ------------------------ */

type BlockRender = BlockStatic<any> & { key: string; events: Record<string, any> };
let curr_block_static: BlockRender = {
	key: uniqueId('component_'),
	component: NoopComponent,
	props: {},
	events: {}
};
let curr_block_static_instance: SvelteComponentTyped;

// dynamic add all new listener
$: if (curr_block_static_instance && curr_block_static.events) {
	for (const [key, listener] of Object.entries(curr_block_static.events)) {
		curr_block_static_instance.$on(key, listener);
	}
}

/* ------------------------ RECURSIVE ASYNC EXECUTER ------------------------ */

function isBlockStatic(block: Blocks): block is BlockStatic<any> {
	return block && !isFunction(block) && !isArray(block) && isObject(block) && 'component' in block;
}
function isBlockFunction(block: Blocks): block is BlockFunction {
	return block && !isFunction(block) && !isArray(block) && isObject(block) && 'function' in block;
}
function isBlockId(block: Blocks): block is BlockContainer {
	return block && !isFunction(block) && !isArray(block) && isObject(block) && 'id' in block;
}

function main(blocks: Blocks[], start: Id) {
	const ids = new Map<Id, BlockContainer>();
	blocks.filter(isBlockId).forEach((b) => ids.set(b.id, b));

	async function render(block: Blocks): Promise<void> {
		if (isBlockId(block)) return render(block.blocks);
		else if (isFunction(block)) return render(await block(get_plain_store()));
		else if (isArray(block)) {
			block.filter(isBlockId).forEach((b) => ids.set(b.id, b));
			for (const b of block) {
				await render(b);
			}
			return;
		} else if (isBlockFunction(block)) {
			switch (block.command) {
				case 'run':
					return await block.function(get_plain_store());
				case 'recursive': {
					const id = await block.function(get_plain_store());
					const next_blocks = ids.get(id);
					if (!next_blocks) {
						alert('get block ids error');
						throw new Error('get block ids error');
					}
					return await render(next_blocks);
				}
				default: {
					throw new Error('block function command not match');
				}
			}
		} else if (isBlockStatic(block)) {
			return new Promise((resolve) => {
				curr_block_static = {
					key: uniqueId('component_'),
					component: block.component,
					props: block.props,
					events: {
						complete() {
							console.log('complete', block.props)
							resolve();
						}
					}
				};
			});
		} else {
			console.log('value error', blocks);
			throw new Error('block recursive value error');
		}
	} // end loop

	const start_blocks = ids.get(start);
	if (!start_blocks) {
		throw new Error('can not start start block');
	}
	return render(start_blocks); // start render from start point
}


onMount(async () => {
	PixiPlugin.registerPIXI(PIXI)
	gsap.registerPlugin(PixiPlugin)
	loader = new PIXI.Loader()
	app = new PIXI.Application({
		width: window.innerWidth,
		height: window.innerHeight,
		backgroundAlpha: 0,
	});
	app.stage.position.set(app.screen.width / 2, app.screen.height / 2)
	document.body.prepend(app.view);
  app.view.classList.add("absolute")

	root = document.documentElement.style
	window['pixi'] = app
	
	main(pages, ID_MAIN);
	console.log('finish');
});
</script>
