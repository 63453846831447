<svelte:options immutable={true} />

<Center>
	{#if visible}
		<form
			class='px-4'
			transition:fade={FADE_CONFIG}
			on:outroend={() => emit('complete')}
			on:submit|preventDefault={() => {
				visible = false;
			}}>
			<label for={label}>
				<div class="pb-4">{@html label}</div>
				<input
					type="text"
					class="p-4 rounded block w-full"
					bind:value={$value}
					required
					placeholder="โปรดเติมคำตอบของท่าน" />
			</label>
			<div class="flex justify-end">
				<button type="submit" class="button is-primary mt-4 text-xs py-2" style='width: 4rem'>ไปต่อ</button>
			</div>
		</form>
	{/if}
</Center>

<script lang="ts">
import { createEventDispatcher } from 'svelte';
import { writable } from 'svelte/store';
import type { Writable } from 'svelte/store';
import { fade } from 'svelte/transition';

import Center from './Center.svelte';
import { FADE_CONFIG } from '../lib/config';

let emit = createEventDispatcher();
let visible = true;

export let value: Writable<string> = writable('');
export let label: string = 'NO LABEL';
</script>
