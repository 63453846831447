<Center>
	{#if visible}
		<div class='px-4' transition:fade={FADE_CONFIG} on:outroend={() => emit('complete')}>
			<div class="mb-4">
				{@html label}
			</div>
			<div class="space-y-2">
				{#each options as opt}
					<button class="button is-primary w-full" on:click={() => click_handler(opt)}>
						{opt}
					</button>
				{/each}
			</div>
		</div>
	{/if}
</Center>

<script lang="ts">
import { createEventDispatcher } from 'svelte';
import { writable } from 'svelte/store';
import type { Writable } from 'svelte/store';
import { fade } from 'svelte/transition';

import Center from './Center.svelte';
import { FADE_CONFIG } from '../lib/config';

let emit = createEventDispatcher();
let visible = true;

export let value: Writable<string> = writable('');
export let label: string = 'NO LABEL';
export let options: string[] = ['DEFAULT CHOICE 1', 'DEFAULT CHOICE 2'];

function click_handler(val: string) {
	value.set(val);
	visible = false;
}
</script>
